import * as React from 'react'
import JobSidebar from '../../components/jobSidebar'
import { CardWrapper } from '../../components/jobCardWithBids'
import styled from 'styled-components'
import { FormGroup, TextField, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useState } from 'react'
import { useAddDealCodeMutation, useHomeownerDealsQuery, useJoinDealMutation } from '../../apollo/generated'
import { useQuery } from '@apollo/react-hooks'
import { GET_SELECTED_PROPERTY_ID } from '../../apollo/queries'


const Wrapper = styled.div`
	padding: 12px 40px 40px 40px;
`

const JobImage = styled.img`
	width: 30%;
	margin-right: 20px;
`

const DescriptionWrapper = styled.div`
	display: flex;
	flex-direction: row;
`

const ContractorDetails = styled.div`
	outline: 1px solid white;
	margin-left: auto;
	padding: 20px;
	text-align: center;
`

const ContractorAvatar = styled.img`
	width: 80px;
	background-color: white;
	border-radius: 50%;
`

const JoinButton = styled(Button)`

`

const HoaPage = () => {
	const [neighbourhoodCode, setNeighbourhoodCode] = useState(null)
	const [addDealCode] = useAddDealCodeMutation();
	const {
		data: { selectedPropertyId }
	} = useQuery(GET_SELECTED_PROPERTY_ID);
	const { data: { homeownerDeals } = { homeownerDeals: [] } } = useHomeownerDealsQuery({
		variables: { active: true },
	})
	const [joinDeal] = useJoinDealMutation();
	const deals = Array.isArray(homeownerDeals) ? homeownerDeals.flatMap(deal => (deal?.bidders || []).filter(db => !!db).map(bidder => ({ ...deal, bidder }))) : [];
	return <JobSidebar>
		<Wrapper>
			<Typography variant="h5" gutterBottom>HOA Opportunities</Typography>
			<Typography>Add you neighbourhood code</Typography>
			<TextField label="Neighbourhood code" value={neighbourhoodCode} onChange={e => setNeighbourhoodCode(e.target.value)}/>
			<Button onClick={() => addDealCode({
				variables: {
					code: neighbourhoodCode,
					propertyId: selectedPropertyId
				},
				refetchQueries: [],
			})}>Add</Button>
			{deals.map(({ id, title, description, frequency, bidder }) => {
				const { id: dealBidderId, priceText, contractor } = bidder;
				return (
					<CardWrapper key={id}>
						<Typography variant="h6" gutterBottom>{title}</Typography>
						<DescriptionWrapper>
							<JobImage src="https://www.onlygreens.com.au/wp-content/uploads/2014/12/tough-garden-jobs.jpg"/>
							<div>
								<Typography variant="h6" gutterBottom>Subscribe and get an incredible discount</Typography>
								<Typography>{description}</Typography>
								<Typography variant="h6" gutterBottom>Price: ${priceText}/{frequency}</Typography>
								<JoinButton
									onClick={() => joinDeal({
										variables: {
											dealBidderId,
											propertyId: selectedPropertyId,
										}
									})}
								>
									Click to join
								</JoinButton>
							</div>
							<ContractorDetails>
								<ContractorAvatar
									src={'https://www.graphicsprings.com/filestorage/stencils/3134dbf0aa940d4786c6ca1ce364a130.png?width=500&height=500'}/>
								<Typography variant="h6" gutterBottom>Gardening CO.</Typography>
								<Typography>{contractor && contractor.user.id}</Typography>
								<Typography>Loads of experience</Typography>
								<Typography>Loads of experience</Typography>
							</ContractorDetails>
						</DescriptionWrapper>
					</CardWrapper>
				)
			})}
		</Wrapper>
	</JobSidebar>
}

export default HoaPage
